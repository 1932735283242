<template>
  <div>
    <div
      v-if="!toggle"
      class="">
      <div class="card-title mt-3">
        <div v-if="canEdit">
          <fa
            icon="pencil-alt"
            class="text-warning mx-2 pointer"
            @click="toggle = !toggle">
          </fa>
          <fa
            icon="arrow-down"
            class="text-primary pointer mx-2 float-right"
            @click="moveDetail(detail.id, 1)">
          </fa>
          <fa
            icon="arrow-up"
            class="text-primary pointer mx-2 float-right"
            @click="moveDetail(detail.id, -1)">
          </fa>
        </div>
      </div>
      <div class="card-text">
        <dl class="row">
          <dt class="col-4 col-sm-3 text-primary font-weight-normal">รายละเอียด:</dt>
          <dd class="col-8 col-sm-9" style="white-space: pre-wrap;">{{detail.content}}</dd>

          <template v-if="detail.correction">
            <dt class="col-4 col-sm-3 text-warning font-weight-normal">การแก้ไข:</dt>
            <dd class="col-8 col-sm-9" style="white-space: pre-wrap;">{{detail.correction}}</dd>
          </template>

          <template v-if="detail.correctionResult">
            <dt class="col-4 col-sm-3 text-success font-weight-normal">ผลการแก้ไข:</dt>
            <dd class="col-8 col-sm-9" style="white-space: pre-wrap;">{{detail.correctionResult}}</dd>
          </template>
        </dl>
      </div>
    </div>

    <div
      class="row mb-4"
      v-if="toggle">
      <div class="col-12">
        <div class="card-title mt-3">
          <fa
            icon="pencil-alt"
            class="text-warning mx-2 pointer"
            @click="toggle = !toggle">
          </fa>
        </div>
      </div>

      <div class="col-12">
        <div class="form-row">
          <sgv-input-textarea
            label="สาเหตุ"
            v-model="formData.content"
            class="col-12"
            :validations="[
              {text: 'required!', value: !$v.formData.content.required && $v.formData.$dirty}
            ]">
          </sgv-input-textarea>

          <sgv-input-textarea
            label="การแก้ไข"
            v-model="formData.correction"
            class="col-12">
          </sgv-input-textarea>

          <sgv-input-textarea
            label="ผลการแก้ไข"
            v-model="formData.correctionResult"
            class="col-12">
          </sgv-input-textarea>
        </div>

        <button
          type="button"
          class="btn btn-warning"
          @click="updateDetail(detail.id)">
          แก้ไข
        </button>

        <button
          type="button"
          class="btn btn-danger float-right"
          @click="destroyDetail(detail.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  UPDATE_DETAIL,
  DESTROY_DETAIL,
  MOVE_DETAIL
} from './graph'

export default {
  props: {
    detail: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        content: '',
        correction: '',
        correctionResult: ''
      }
    }
  },
  validations: {
    formData: {
      content: {required},
    }
  },
  methods: {
    updateDetail (id) {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_DETAIL(this.templateType),
        variables: {
          paperType: this.paperType,
          detailId: id,
          input: this.formData
        }
      })
      .then(() => {
        this.$v.formData.$reset()
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
        this.toggle = false
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyDetail (id) {
      this.$apollo.mutate({
        mutation: DESTROY_DETAIL(this.templateType),
        variables: {
          paperType: this.paperType,
          detailId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    moveDetail (id, step) {
      this.$apollo.mutate({
        mutation: MOVE_DETAIL(this.templateType),
        variables: {
          paperType: this.paperType,
          detailId: id,
          step
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
      })
      .catch(this.$toasted.global.error)
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  watch: {
    detail: {
      handler (v) {
        this.formData.content = v.content
        this.formData.correction = v.correction
        this.formData.correctionResult = v.correctionResult
      },
      immediate: true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
