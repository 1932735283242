import { render, staticRenderFns } from "./DetailFormUser.vue?vue&type=template&id=7aaf739f&scoped=true&"
import script from "./DetailFormUser.vue?vue&type=script&lang=js&"
export * from "./DetailFormUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aaf739f",
  null
  
)

export default component.exports