<template>
  <div>
    <div class="card">
      <h6 class="card-header" v-if="!toggle">
        {{topic.recordedAt | month}}
        <span v-if="!isApproved">
          <fa
            v-if="!topic.approvedAt"
            icon="pencil-alt"
            class="text-warning ml-2 pointer"
            @click="toggle = !toggle">
          </fa>
          <fa
            icon="lock"
            class="mx-2 pointer"
            :class="{
              'text-danger': topic.approvedAt,
              'text-secondary': !topic.approvedAt,
            }"
            @click="toggleLock">
          </fa>
        </span>
        <span class="float-right">
          <span class="ml-3">ค่าจริง: {{topic.actualValue | comma(-1)}}</span>
        </span>
      </h6>

      <div class="card-header" v-if="toggle">
        <h6 class="card-title">
          {{topic.recordedAt | month}}
          <fa
            icon="pencil-alt"
            class="text-warning ml-1 pointer"
            @click="toggle = !toggle">
          </fa>
        </h6>

        <div class="form-row">
          <sgv-input-month
            label="เดือน"
            v-model="formData.recordedAt"
            class="col-6"
            :validations="[
              {text: 'required!', value: !$v.formData.recordedAt.required && $v.formData.$dirty}
            ]">
          </sgv-input-month>

          <sgv-input-number
            label="ค่าจริง"
            :precision="2"
            v-model="formData.actualValue"
            class="col-6"
            :validations="[
              {text: 'required!', value: !$v.formData.actualValue.required && $v.formData.$dirty},
              {text: 'จำนวนมากกว่า 0 ถึง 25', value: !$v.formData.actualValue.between && $v.formData.$dirty}
            ]">
          </sgv-input-number>
        </div>

        <button
          type="button"
          class="btn btn-warning"
          @click="updateTopic(topic.id)">
          แก้ไข
        </button>

        <button
          type="button"
          class="btn btn-danger float-right"
          @click="destroyTopic(topic.id)">
          ลบ
        </button>
      </div>

      <DetailTopicDetail
        class="card-body py-0"
        v-for="detail in topic.details"
        :key="detail.id"
        :detail="detail"
        :canEdit="toggle"
        :paperType="paperType"
        :templateType="templateType"
        @updated="emitUpdated">
      </DetailTopicDetail>

      <button
        v-if="toggle"
        type="button"
        class="btn btn-outline-success mx-3 my-3"
        @click="createDetail(topic.id)">
        เพิ่มรายละเอียด
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  UPDATE_TOPIC,
  DESTROY_TOPIC,
  LOCK_TOPIC,
  UNLOCK_TOPIC,
  CREATE_DETAIL,
} from './graph'
import DetailTopicDetail from './DetailTopicDetail'

export default {
  props: {
    topic: {
      type: Object,
      required: true
    },
    isApproved: {
      type: Boolean,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        recordedAt: null,
        targetValue: 25,
        actualValue: 0
      }
    }
  },
  validations: {
    formData: {
      recordedAt: {required},
      targetValue: {
        required,
        between: function(point) {
          return point > 0 && point <= 25
        }
      },
      actualValue: {
        required,
        between: function(point) {
          return point > 0 && point <= 25
        }
      },
      details: {
        $each: {
          content: {required},
        }
      }
    }
  },
  methods: {
    updateTopic (id) {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id,
          input: {
            ...this.formData,
            recordedAt: this.$date.format(this.formData.recordedAt + '-01').utc
          }
        }
      })
      .then(() => {
        this.$v.formData.$reset()
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
        this.toggle = false
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyTopic (id) {
      this.$apollo.mutate({
        mutation: DESTROY_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    createDetail (topicId) {
      this.$apollo.mutate({
        mutation: CREATE_DETAIL(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId,
          input: {
            content: '-',
            correction: '',
            correctionResult: ''
          }
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    toggleLock () {
      if (this.topic.approvedAt) this.unlockTopic(this.topic.id)
      else this.lockTopic(this.topic.id)
    },
    lockTopic (id) {
      this.$apollo.mutate({
        mutation: LOCK_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("อนุมัติสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    unlockTopic (id) {
      this.$apollo.mutate({
        mutation: UNLOCK_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ย้อนรายการสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    emitUpdated () {
      this.$emit('updated', null)
    },
  },
  watch: {
    topic: {
      handler (v) {
        this.formData.recordedAt = v.recordedAt
        this.formData.targetValue = v.targetValue
        this.formData.actualValue = v.actualValue
      },
      immediate: true
    }
  },
  components: {
    DetailTopicDetail
  }
}
</script>

<style lang="css" scoped>
</style>
