var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-4 col-sm-4",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"รหัส"},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('sgv-input-text',{staticClass:"col-8 col-sm-8",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ชื่อ","validations":[
        {text: 'required!', value: _vm.v.formData.name.$dirty && _vm.v.formData.name.$error}
      ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-textarea',{staticClass:"col-12 col-sm-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ผลกระทบ","validations":[
        {text: 'required!', value: !_vm.v.formData.options.effect.required && _vm.v.formData.$dirty}
      ]},model:{value:(_vm.formData.options.effect),callback:function ($$v) {_vm.$set(_vm.formData.options, "effect", $$v)},expression:"formData.options.effect"}}),_c('sgv-input-textarea',{staticClass:"col-12 col-sm-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"มาตรการควบคุม"},model:{value:(_vm.formData.options.revisionPlan),callback:function ($$v) {_vm.$set(_vm.formData.options, "revisionPlan", $$v)},expression:"formData.options.revisionPlan"}})],1),(_vm.$auth.disabled(_vm.method))?_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":"","label":"ผู้รับผลกระทบ","value":_vm.formData.options.affectedPerson}}),_c('sgv-input-text',{staticClass:"col-6 col-sm-3",attrs:{"disabled":"","label":"ความรุนแรง","value":_vm.formData.options.riskPoint}}),_c('sgv-input-text',{staticClass:"col-6 col-sm-3",attrs:{"disabled":"","label":"โอกาส","value":_vm.formData.options.probabilityPoint}}),_c('sgv-input-number',{staticClass:"col-6 col-sm-3",attrs:{"disabled":"","label":"ปริมาณต่อปี","minus":"","precision":2,"value":_vm.formData.options.probabilityCount}}),_c('sgv-input-number',{staticClass:"col-6 col-sm-3",attrs:{"disabled":"","label":"เฉลี่ยข้อมูล (เดือน)","value":_vm.formData.options.monthCount}})],1):_vm._e(),(!_vm.$auth.disabled(_vm.method))?_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"label":"ผู้รับผลกระทบ","validations":[
        {text: 'required!', value: !_vm.v.formData.options.affectedPerson.required && _vm.v.formData.$dirty}
      ]},model:{value:(_vm.formData.options.affectedPerson),callback:function ($$v) {_vm.$set(_vm.formData.options, "affectedPerson", $$v)},expression:"formData.options.affectedPerson"}}),_c('sgv-input-radio',{staticClass:"col-12",attrs:{"label":"(คะแนน 1-5) ความรุนแรง","options":_vm.riskOptions,"select":"value","inline":"","validations":[
        {text: 'required!', value: !_vm.v.formData.options.riskPoint.required && _vm.v.formData.$dirty},
        {text: 'จำนวนไม่อยู่ในช่วง', value: !_vm.v.formData.options.riskPoint.between && _vm.v.formData.$dirty}
      ]},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.text)+" ")]}}],null,false,2460581028),model:{value:(_vm.formData.options.riskPoint),callback:function ($$v) {_vm.$set(_vm.formData.options, "riskPoint", $$v)},expression:"formData.options.riskPoint"}}),_c('sgv-input-radio',{staticClass:"col-12",attrs:{"label":"(คะแนน 1-5) โอกาส","options":_vm.probabilityOptions,"select":"value","inline":"","validations":[
        {text: 'required!', value: !_vm.v.formData.options.probabilityPoint.required && _vm.v.formData.$dirty},
        {text: 'จำนวนไม่อยู่ในช่วง', value: !_vm.v.formData.options.probabilityPoint.between && _vm.v.formData.$dirty}
      ]},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.text)+" ")]}}],null,false,2460581028),model:{value:(_vm.formData.options.probabilityPoint),callback:function ($$v) {_vm.$set(_vm.formData.options, "probabilityPoint", $$v)},expression:"formData.options.probabilityPoint"}}),_c('sgv-input-number',{staticClass:"col-6",attrs:{"label":"ปริมาณต่อปี","precision":2,"minus":"","validations":[
        {text: 'required!', value: !_vm.v.formData.options.probabilityCount.required && _vm.v.formData.$dirty},
        {text: 'จำนวนไม่อยู่ในช่วง', value: !_vm.v.formData.options.probabilityCount.between && _vm.v.formData.$dirty}
      ]},model:{value:(_vm.formData.options.probabilityCount),callback:function ($$v) {_vm.$set(_vm.formData.options, "probabilityCount", $$v)},expression:"formData.options.probabilityCount"}}),_c('sgv-input-number',{staticClass:"col-6",attrs:{"label":"เฉลี่ยข้อมูล (เดือน)","validations":[
        {text: 'required!', value: !_vm.v.formData.options.monthCount.required && _vm.v.formData.$dirty},
        {text: 'จำนวนมากกว่า 0', value: !_vm.v.formData.options.monthCount.between && _vm.v.formData.$dirty}
      ]},model:{value:(_vm.formData.options.monthCount),callback:function ($$v) {_vm.$set(_vm.formData.options, "monthCount", $$v)},expression:"formData.options.monthCount"}})],1):_vm._e(),(_vm.paperId > 0)?_c('div',{staticClass:"form-row"},[_c('DetailFormUser',{staticClass:"col-6",attrs:{"title":"จัดทำ/ทบทวน","value":_vm.getUser('reviewed'),"isSubmit":!_vm.formData.approvedAt && !_vm.formData.reviewedAt && _vm.$auth.hasRole(("paper:" + _vm.paperType + ":review")),"isUndo":!_vm.formData.approvedAt && _vm.formData.reviewedAt && _vm.$auth.hasRole(("paper:" + _vm.paperType + ":review")),"submitFunc":_vm.reviewSubmit,"undoFunc":_vm.reviewUndo},on:{"updated":function($event){return _vm.$emit('updated', null)}}}),_c('DetailFormUser',{staticClass:"col-6",attrs:{"title":"อนุมัติ","value":_vm.getUser('approved'),"isSubmit":_vm.formData.reviewedAt && !_vm.formData.approvedAt && _vm.$auth.hasRole(("paper:" + _vm.paperType + ":approve")),"isUndo":_vm.formData.reviewedAt && _vm.formData.approvedAt && _vm.$auth.hasRole(("paper:" + _vm.paperType + ":approve")),"submitFunc":_vm.approveSubmit,"undoFunc":_vm.approveUndo},on:{"updated":function($event){return _vm.$emit('updated', null)}}})],1):_vm._e(),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('sgv-input-check',{attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ใช้งาน","inline":""},model:{value:(_vm.formData.isActive),callback:function ($$v) {_vm.$set(_vm.formData, "isActive", $$v)},expression:"formData.isActive"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }