import gql from 'graphql-tag'

const detailResponse = `
  id type recordedAt targetValue actualValue
  summary
  reviewedAt reviewedUser {id name}
  approvedAt approvedUser {id name}
  details {
    id content correction correctionResult
  }
`

export const LIST_TOPIC = (templateType) => gql`query LIST_TOPIC ($paperType: String!, $paperId: Int!, $year: Int!) {
  topics: list${templateType}Topic (paperType: $paperType, paperId: $paperId, year: $year) {${detailResponse}}
}`

export const CREATE_TOPIC = (templateType) => gql`mutation CREATE_TOPIC ($paperType: String!, $paperId: Int!, $input: ${templateType}TopicInput!) {
  createTopic: create${templateType}Topic (paperType: $paperType, paperId: $paperId, input: $input) {${detailResponse}}
}`

export const UPDATE_TOPIC = (templateType) => gql`mutation UPDATE_TOPIC ($paperType: String!, $topicId: Int!, $input: ${templateType}TopicInput!) {
  updateTopic: update${templateType}Topic (paperType: $paperType, topicId: $topicId, input: $input) {${detailResponse}}
}`

export const DESTROY_TOPIC = (templateType) => gql`mutation DESTROY_TOPIC ($paperType: String!, $topicId: Int!) {
  destroyTopic: destroy${templateType}Topic (paperType: $paperType, topicId: $topicId) {id}
}`

export const LOCK_TOPIC = (templateType) => gql`mutation LOCK_TOPIC ($paperType: String!, $topicId: Int!) {
  lockTopic: lock${templateType}Topic (paperType: $paperType, topicId: $topicId) {${detailResponse}}
}`

export const UNLOCK_TOPIC = (templateType) => gql`mutation UNLOCK_TOPIC ($paperType: String!, $topicId: Int!) {
  unlockTopic: unlock${templateType}Topic (paperType: $paperType, topicId: $topicId) {${detailResponse}}
}`

export const UPDATE_TOPIC_APPROVE = (templateType) => gql`mutation UPDATE_TOPIC_APPROVE ($paperType: String!, $yearAt: Int!, $paperId: Int!, $topicId: Int!, $input: ${templateType}TopicInput!) {
  updateTopic: update${templateType}TopicApprove (paperType: $paperType, yearAt: $yearAt, paperId: $paperId, topicId: $topicId, input: $input) {${detailResponse}}
}`

export const REVIEW_SUBMIT_PAPER_TOPIC = (templateType) => gql`mutation REVIEW_SUBMIT_PAPER_TOPIC ($paperType: String!, $yearAt: Int!, $paperId: Int!, $topicId: Int!, $submitDate: Date!) {
  topic: reviewSubmit${templateType}Topic (paperType: $paperType, yearAt: $yearAt, paperId: $paperId, topicId: $topicId, submitDate: $submitDate) {${detailResponse}}
}`

export const REVIEW_UNDO_PAPER_TOPIC = (templateType) => gql`mutation REVIEW_UNDO_PAPER_TOPIC ($paperType: String!, $yearAt: Int!, $paperId: Int!, $topicId: Int!) {
  topic: reviewUndo${templateType}Topic (paperType: $paperType, yearAt: $yearAt, paperId: $paperId, topicId: $topicId) {${detailResponse}}
}`

export const APPROVE_SUBMIT_PAPER_TOPIC = (templateType) => gql`mutation APPROVE_SUBMIT_PAPER_TOPIC ($paperType: String!, $yearAt: Int!, $paperId: Int!, $topicId: Int!, $submitDate: Date!) {
  topic: approveSubmit${templateType}Topic (paperType: $paperType, yearAt: $yearAt, paperId: $paperId, topicId: $topicId, submitDate: $submitDate) {${detailResponse}}
}`

export const APPROVE_UNDO_PAPER_TOPIC = (templateType) => gql`mutation APPROVE_UNDO_PAPER_TOPIC ($paperType: String!, $yearAt: Int!, $paperId: Int!, $topicId: Int!) {
  topic: approveUndo${templateType}Topic (paperType: $paperType, yearAt: $yearAt, paperId: $paperId, topicId: $topicId) {${detailResponse}}
}`
