<template>
  <div>
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-4 col-sm-4"
        label="รหัส"
        v-model="formData.code">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-8 col-sm-8"
        label="ชื่อ"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
        ]">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        label="ผลกระทบ"
        v-model="formData.options.effect"
        class="col-12 col-sm-6"
        :validations="[
          {text: 'required!', value: !v.formData.options.effect.required && v.formData.$dirty}
        ]">
      </sgv-input-textarea>

      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        label="มาตรการควบคุม"
        v-model="formData.options.revisionPlan"
        class="col-12 col-sm-6">
      </sgv-input-textarea>
    </div>

    <div class="form-row" v-if="$auth.disabled(method)">
      <sgv-input-text
        disabled
        label="ผู้รับผลกระทบ"
        :value="formData.options.affectedPerson"
        class="col-12">
      </sgv-input-text>

      <sgv-input-text
        disabled
        label="ความรุนแรง"
        :value="formData.options.riskPoint"
        class="col-6 col-sm-3">
      </sgv-input-text>

      <sgv-input-text
        disabled
        label="โอกาส"
        :value="formData.options.probabilityPoint"
        class="col-6 col-sm-3">
      </sgv-input-text>

      <sgv-input-number
        disabled
        label="ปริมาณต่อปี"
        minus
        :precision="2"
        :value="formData.options.probabilityCount"
        class="col-6 col-sm-3">
      </sgv-input-number>

      <sgv-input-number
        disabled
        label="เฉลี่ยข้อมูล (เดือน)"
        :value="formData.options.monthCount"
        class="col-6 col-sm-3">
      </sgv-input-number>
    </div>

    <div class="form-row" v-if="!$auth.disabled(method)">
      <sgv-input-text
        label="ผู้รับผลกระทบ"
        v-model="formData.options.affectedPerson"
        class="col-12"
        :validations="[
          {text: 'required!', value: !v.formData.options.affectedPerson.required && v.formData.$dirty}
        ]">
      </sgv-input-text>

      <sgv-input-radio
        label="(คะแนน 1-5) ความรุนแรง"
        class="col-12"
        :options="riskOptions"
        select="value"
        inline
        v-model="formData.options.riskPoint"
        :validations="[
          {text: 'required!', value: !v.formData.options.riskPoint.required && v.formData.$dirty},
          {text: 'จำนวนไม่อยู่ในช่วง', value: !v.formData.options.riskPoint.between && v.formData.$dirty}
        ]">
        <template slot-scope="option">
          {{option.text}}
        </template>
      </sgv-input-radio>

      <sgv-input-radio
        label="(คะแนน 1-5) โอกาส"
        class="col-12"
        :options="probabilityOptions"
        select="value"
        inline
        v-model="formData.options.probabilityPoint"
        :validations="[
          {text: 'required!', value: !v.formData.options.probabilityPoint.required && v.formData.$dirty},
          {text: 'จำนวนไม่อยู่ในช่วง', value: !v.formData.options.probabilityPoint.between && v.formData.$dirty}
        ]">
        <template slot-scope="option">
          {{option.text}}
        </template>
      </sgv-input-radio>

      <sgv-input-number
        label="ปริมาณต่อปี"
        v-model="formData.options.probabilityCount"
        class="col-6"
        :precision="2"
        minus
        :validations="[
          {text: 'required!', value: !v.formData.options.probabilityCount.required && v.formData.$dirty},
          {text: 'จำนวนไม่อยู่ในช่วง', value: !v.formData.options.probabilityCount.between && v.formData.$dirty}
        ]">
      </sgv-input-number>

      <sgv-input-number
        label="เฉลี่ยข้อมูล (เดือน)"
        v-model="formData.options.monthCount"
        class="col-6"
        :validations="[
          {text: 'required!', value: !v.formData.options.monthCount.required && v.formData.$dirty},
          {text: 'จำนวนมากกว่า 0', value: !v.formData.options.monthCount.between && v.formData.$dirty}
        ]">
      </sgv-input-number>
    </div>

    <div class="form-row" v-if="paperId > 0">
      <DetailFormUser
        class="col-6"
        title="จัดทำ/ทบทวน"
        :value="getUser('reviewed')"
        :isSubmit="!formData.approvedAt && !formData.reviewedAt && $auth.hasRole(`paper:${paperType}:review`)"
        :isUndo="!formData.approvedAt && formData.reviewedAt && $auth.hasRole(`paper:${paperType}:review`)"
        :submitFunc="reviewSubmit"
        :undoFunc="reviewUndo"
        @updated="$emit('updated', null)">
      </DetailFormUser>

      <DetailFormUser
        class="col-6"
        title="อนุมัติ"
        :value="getUser('approved')"
        :isSubmit="formData.reviewedAt && !formData.approvedAt && $auth.hasRole(`paper:${paperType}:approve`)"
        :isUndo="formData.reviewedAt && formData.approvedAt && $auth.hasRole(`paper:${paperType}:approve`)"
        :submitFunc="approveSubmit"
        :undoFunc="approveUndo"
        @updated="$emit('updated', null)">
      </DetailFormUser>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="ใช้งาน"
          inline
          v-model="formData.isActive">
        </sgv-input-check>
      </div>
    </div>
  </div>
</template>

<script>
import DetailFormUser from './DetailFormUser'
import {
  REVIEW_SUBMIT_PAPER,
  REVIEW_UNDO_PAPER,
  APPROVE_SUBMIT_PAPER,
  APPROVE_UNDO_PAPER,
} from './graph'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      riskOptions: [
        {text: '(1) ไม่มีผลกระทบ', value: 1},
        {text: '(2) เกิดผลกระทบเล็กน้อย', value: 2},
        {text: '(3) เกิดผลดระทบกับหน่วยงานอื่น', value: 3},
        {text: '(4) ทำให้กระบวนการล้มเหลว', value: 4},
        {text: '(5) ระบบล้มเหลว', value: 5}
      ],
      probabilityOptions: [
        {text: '(1) แทบไม่มีโอกาศ', value: 1},
        {text: '(2) ค่อนข้างเล็กน้อย ปีละครั้ง', value: 2},
        {text: '(3) 50/50 6เดือนครั้ง', value: 3},
        {text: '(4) เดือนละครั้ง', value: 4},
        {text: '(5) เกิดขึ้นทุกวัน', value: 5}
      ],
    }
  },
  methods: {
    getUser (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    reviewSubmit (date) {
      return this.$apollo.mutate({
        mutation: REVIEW_SUBMIT_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          submitDate: date
        }
      })
    },
    reviewUndo () {
      return this.$apollo.mutate({
        mutation: REVIEW_UNDO_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
        }
      })
    },
    approveSubmit (date) {
      return this.$apollo.mutate({
        mutation: APPROVE_SUBMIT_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          submitDate: date
        }
      })
    },
    approveUndo () {
      return this.$apollo.mutate({
        mutation: APPROVE_UNDO_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
        }
      })
    },
  },
  components: {
    DetailFormUser
  }
}
</script>

<style lang="css">

</style>
