<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>
    </div>

    <hr class="my-3">

    <div>
      <sgv-form :method.sync="method" :options="options">
        <DetailForm
          :paperId="paperId"
          :paperType="paperType"
          :templateType="templateType"
          :formData="formData"
          :method="method"
          :v="$v"
          @updated="refetch">
        </DetailForm>
      </sgv-form>

      <DetailYear
        v-if="paperId > 0"
        :paperId="paperId"
        :paperType="paperType"
        :templateType="templateType"
        :topic="topiceApproval"
        v-model="year"
        @updated="refetch">
      </DetailYear>

      <template v-if="paperId !== 0">
        <HrDivider :options="tabs" v-model="selectedTab"></HrDivider>

        <keep-alive>
          <div v-if="selectedTab === 'DetailTopic'" class="row">
            <DetailChart
              class="col-12"
              :items="topicsMap.filter(v => v.type === 'topic')">
            </DetailChart>

            <DetailTopic
              class="my-2 col-12"
              v-for="(topic, i) in topicsMap.filter(v => v.type === 'topic')"
              :key="topic.id"
              :index="i"
              :paperId="paperId"
              :paperType="paperType"
              :templateType="templateType"
              :topic="topic"
              :isApproved="isTopicApproved"
              @updated="refetch">
            </DetailTopic>
          </div>

          <div v-else-if="selectedTab === 'DetailLog'">
            <DetailLog
              :paperId="paperId"
              :paperType="paperType"
              :templateType="templateType"
              :method="method">
            </DetailLog>
          </div>

          <div v-else-if="selectedTab === 'DetailAttachment'">
            <DetailAttachment
              :paperId="paperId"
              :paperType="paperType"
              :templateType="templateType"
              :canDestroy="!isTopicApproved">
            </DetailAttachment>
          </div>

          <div v-else-if="selectedTab === 'DetailPrint'">
            <DetailPrint
              :paperId="paperId"
              :paperType="paperType"
              :templateType="templateType"
              :formData="formData"
              :url="`/paper/${paperType}/${paperId}/${year}/print`"
              :filename="`${formData.code}-${year}.pdf`">
            </DetailPrint>
          </div>
        </keep-alive>
      </template>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm'
import DetailAttachment from '@/views/paper_template/components/Attachment'
import DetailChart from './DetailChart'
import DetailTopic from './DetailTopic'
import DetailYear from './DetailYear'
import DetailPrint from '@/views/paper_template/components/DetailPrint'
import {
  DETAIL_PAPER,
  CREATE_PAPER,
  UPDATE_PAPER,
  DESTROY_PAPER,
  LIST_TOPIC,
} from './graph'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      year: this.$date.current().yyyy.toString(),
      topics: [],
      formData: {
        code: '',
        name: '',
        options: {
          effect: '',
          affectedPerson: '',
          riskPoint: 0,
          probabilityPoint: 0,
          probabilityCount: 0,
          revisionPlan: '',
          monthCount: 3
        },
        isActive: true,
        reviewedAt: null,
        reviewedUser: {},
        approvedAt: null,
        approvedUser: {},
      },
      method: 'info',
      listView: `Paper${this.$form.capitalize(this.paperType)}List`,
      selectedTab: 'DetailTopic',
      isLoaded: false,
      tabs: [
        {text: 'รายการ', value: 'DetailTopic'},
        {text: 'ไฟล์แนบ', value: 'DetailAttachment'},
      ],
      actions: []
    }
  },
  validations: {
    formData: {
      name: {required},
      options: {
        effect: {required},
        affectedPerson: {required},
        riskPoint: {
          required,
          between: function(point) {
            return point > 0 && point <= 5
          }
        },
        probabilityPoint: {
          required,
          between: function(point) {
            return point > 0 && point <= 5
          }
        },
        probabilityCount: {
          required,
          between: function(point) {
            return point !== 0
          }
        },
        monthCount: {
          required,
          between: function(point) {
            return point > 0
          }
        },
      }
    }
  },
  apollo: {
    topics: {
      query () {
        return LIST_TOPIC(this.templateType)
      },
      variables () {
        return {
          paperType: this.paperType,
          paperId: this.paperId,
          year: +this.year
        }
      },
      debounce: 500,
      fetchPolicy: 'no-cache',
      skip () {
        return isNaN(this.year)
      }
    }
  },
  computed: {
    topicsMap () {
      return this.topics.map(topic => {
        return {
          id: topic.id,
          type: topic.type,
          recordedAt: this.$date.format(topic.recordedAt).month,
          targetValue: topic.targetValue,
          actualValue: topic.actualValue,
          isActive: topic.isActive,
          details: topic.details,
          target: topic.target,
          calculation: topic.calculation,
          summary: topic.summary,
          reviewedAt: topic.reviewedAt,
          reviewedUser: topic.reviewedUser,
          approvedAt: topic.approvedAt,
          approvedUser: topic.approvedUser,
        }
      })
    },
    topiceApproval () {
      const found = this.topicsMap.find(v => v.type === 'approve')
      if (found) return found

      return {
        id: 0,
        type: 'approve',
        target: '',
        calculation: '',
        summary: '',
        reviewedAt: null,
        reviewedUser: {
          id: 0,
          name: ''
        },
        approvedAt: null,
        approvedUser: {
          id: 0,
          name: ''
        }
      }
    },
    isTopicApproved () {
      const found = this.topics.find(v => v.type === 'approve')
      if (found && found.approvedAt) return true
      return false
    },
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.formData.reviewedAt || !this.$auth.hasRole(`paper:${this.paperType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.formData.reviewedAt || !this.$auth.hasRole(`paper:${this.paperType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    refetch () {
      this.dataDetail()
      this.$apollo.queries.topics.refetch()
    },
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.setFormData(res.data.paper)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
      .finally(() => {
        this.isLoaded = true
      })
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'options') {
          this.formData.options.effect = v.options?.effect || ''
          this.formData.options.affectedPerson = v.options?.affectedPerson || ''
          this.formData.options.revisionPlan = v.options?.revisionPlan || ''
          this.formData.options.riskPoint = v.options?.riskPoint || 0
          this.formData.options.probabilityPoint = v.options?.probabilityPoint || 0
          this.formData.options.probabilityCount = v.options?.probabilityCount || 0
          this.formData.options.monthCount = v.options?.monthCount || 3
        } else {
          this.formData[key] = v[key]
        }
      })
    },
    serializeInput (v) {
      return  {
        code: v.code,
        name: v.name,
        isActive: v.isActive,
        options: v.options
      }
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, paperId: res.data.paper.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_PAPER(this.templateType),
        variables: {
          paperId: this.paperId,
          paperType: this.paperType,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.paper)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_PAPER(this.templateType),
        variables: {
          paperId: this.paperId,
          paperType: this.paperType,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.listView) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$router.push({name: this.listView})
      }
    }
  },
  created () {
    if (this.paperId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
  },
  components: {
    DetailForm,
    DetailChart,
    DetailTopic,
    DetailPrint,
    DetailAttachment,
    DetailYear
  }
}
</script>

<style lang="css">
</style>
